(function ($) {
  Drupal.behaviors.headerOffersBannerFormatterV1 = {
    attach: function (context) {
      var $formatter = $('.js-header-offers-banner-formatter--v1', context);

      $formatter.each(function () {
        var $this = $(this);
        var $carousel = $this.find('.js-header-offers-banner-formatter-carousel');
        var autoplay = $carousel.data('slides-autoplay');
        var arrows = $carousel.data('arrows');
        var speed = parseInt($carousel.data('speed'));
        // Fade settings
        var fade = $carousel.data('fade') || false;
        // Plain with defaults
        var settings = {
          infinite: true,
          autoplay: autoplay,
          fade: fade,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: arrows,
          speed: speed
        };

        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
